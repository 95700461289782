<template>
  <main class="main" data-view="dictionary">
    <section class="section" id="dictionary">
      <div class="container">
            <div class="row">
                <div class="rowLeft">
                  <h3>{{ cc.title1 }}</h3>
                  <h1>{{ cc.title2 }}</h1>
                  <h2 v-html="cc.title3"></h2>
                </div>
                <div class="rowRight">
                  <img src="/img/pages/dictionary/books-pile.png" alt="books" width="284" height="284" class="books">
                  <img src="/img/pages/dictionary/mycoiner_logo_gray.svg" alt="logo" width="284" height="284" class="logo">
                </div>
            </div>
            <div class="search">
                    <input type="text" placeholder="Napište název pojmu..."
                     v-model="searchedTerm"
                     v-on:keyup.enter="searchForTerm(searchedTerm)">
                    <i class="searchIcon">
                      <img src="/img/icons/dictionary/ico-dictionary-search.svg">
                    </i>
                      <button type="submit" @click="searchForTerm(searchedTerm)">Vyhledat</button>
            </div>
      </div>
        <ul class="alphabetList">
          <li :id="cc.all"
              ref="first"
              @click="(event) => {
                if (previousItem != null) {
                  previousItem.classList.remove('active')
                }

                event.target.classList.add('active');
                previousItem = event.target;
                isFullscreen = false
                setCurrentActiveItem(cc.all)
                }"
              >
            {{ cc.all }}
          </li>
          <li :id="value"
              @click="(event) => {
                if (previousItem != null) {
                  previousItem.classList.remove('active')
                }

                event.target.classList.add('active');
                previousItem = event.target;
                isFullscreen = false
                setCurrentActiveItem(value)
                }"
              v-for="value in alphabet" :key="value"
              :ref="value">
            {{ value }}
          </li>
        </ul>
      <ul class="terms" v-if="!isFullscreen" leftStatus = null rightStatus = null>
        <li v-for=" (visibleTerm, index) in visibleTerms"
         v-bind:key="index"
         @click="isFullscreenTerm = visibleTerm, isFullscreen = true, leftStatus = 'active', rightStatus = 'active'">
          <h4>{{ visibleTerm.key }}</h4>
          <p>{{ visibleTerm.value }}</p>
        </li>
      </ul>
      <ul class="term" v-if="isFullscreen">
        <li>
          <h4>{{ isFullscreenTerm.key }}</h4>
          <p>{{ isFullscreenTerm.value }}</p>
          <p class="comm">{{ isFullscreenTerm.comment }}</p>
        </li>
      </ul>
      <div class="arrows">
        <i class="left" href="#" @click="updatePagePosLeft()" :class="leftStatus">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="30" height="35">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </i>
        <i class="right" href="#" @click="updatePagePosRight()" :class="rightStatus">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="30" height="35">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
        </i>
      </div>
    </section>
    <section class="section section--snow" id="s4">
      <comp-contact />
    </section>
  </main>
</template>
<script>
import compContact from '@/components/Contact'
import termsData from '@/data/dictionary/pojmy.json'
export default {
  components: {
    compContact
  },
  computed: {
    cc () {
      return this.$t('pages.dictionary')
    }
  },
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
    window.addEventListener('resize', this.detectWidth())

    this.setCurrentActiveItem(this.cc.all)
    this.$refs.first.classList.add('active')
    this.previousItem = this.$refs.first

    document.querySelector('#termCount').innerText = this.termsArray.length
  },
  unmounted () {
    window.removeEventListener('resize', this.detectWidth())
  },
  metaInfo () {
    const meta = this.$t('meta.dictionary')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { property: 'og:description', content: meta.description }
      ]
    }
  },
  data () {
    return {
      alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      termsArray: termsData,
      pagePos: 0,
      previousItem: null,
      pageSize: 6,
      isActive: false,
      leftStatus: null,
      rightStatus: null,
      isFullscreen: false,
      isFullscreenTerm: null,
      searchedTerm: null,
      visibleTerms: [],
      filteredTerms: []
    }
  },
  methods: {
    detectWidth () {
      if (window.innerWidth < 510) {
        this.pageSize = 2
      } else {
        this.pageSize = 6
      }
    },
    updatePagePosRight () {
      if (!(this.rightStatus === 'active')) {
        if (this.pagePos < parseInt(this.filteredTerms.length / this.pageSize)) {
          this.pagePos++
        }
        this.visibleTerms = this.filteredTerms.slice(this.pageSize * this.pagePos, this.pageSize * (this.pagePos + 1))
        this.leftArrowStatus()
        this.rightArrowStatus()
      }
    },
    updatePagePosLeft () {
      if (!(this.leftStatus === 'active')) {
        if (this.pagePos > 0) {
          this.pagePos--
        }
        this.visibleTerms = this.filteredTerms.slice(this.pageSize * this.pagePos, this.pageSize * (this.pagePos + 1))
        this.leftArrowStatus()
        this.rightArrowStatus()
      }
    },
    leftArrowStatus () {
      if (this.pageSize * this.pagePos < 6) {
        this.leftStatus = 'active'
      } else {
        this.leftStatus = null
      }
    },
    rightArrowStatus () {
      if (this.visibleTerms.length < 6) {
        this.rightStatus = 'active'
      } else {
        this.rightStatus = null
      }
    },
    setCurrentActiveItem (name) {
      if (name === this.cc.all) {
        this.filteredTerms = this.termsArray
      } else {
        this.filteredTerms = this.termsArray.filter((term) => {
          return term.key.toLowerCase().startsWith(name.toLowerCase())
        })
      }
      this.pagePos = 0
      this.visibleTerms = this.filteredTerms.slice(this.pageSize * this.pagePos, this.pageSize * (this.pagePos + 1))
      this.leftArrowStatus()
      this.rightArrowStatus()
    },
    searchForTerm (name) {
      if (this.searchedTerm != null) {
        this.setCurrentActiveItem(name)
      }
    }
  }
}
</script>
